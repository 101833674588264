require('../css/main.css')
require('../css/header.css')
require('../css/hero.css')
require('../css/portfolio.css')
require('../css/about.css')
// require('../css/team.css')
require('../css/careers.css')
require('../css/footer.css')
// require('../css/press.css')
require('../css/companies.css')
require('../css/contacts.css')
require('../css/profile.css')
require('../css/privacy.css')

// Scroll
class Scroll {
  constructor(navigation) {
    this.navigation = navigation
    this.links = navigation.querySelectorAll('.nav-link')
    this.logo = document.getElementById('logo-link')
  }

  init() {
    this.links.forEach(link => {
      link.addEventListener('click', e => {
        this.scrollToTarget(e)
        this.updateClasses(e)
      })
    })

    this.logo.addEventListener('click', e => {
      this.scrollToTop(e)
      this.updateClasses()
    })
  }

  updateClasses(e) {
    this.links.forEach(link => link.classList.remove("nav-link-active"));

    if (e) {
      e.target.classList.add("nav-link-active");
    } else {
      this.links[0].classList.add("nav-link-active");
    }
  }

  scrollToTarget(e) {
    e.preventDefault()
    const target = e.target.dataset.link
    const el = document.getElementById(target)

    const top = el.getBoundingClientRect().top
    const scroll = top + window.scrollY - 100

    if (el) {
      window.scroll({ top: scroll, behavior: 'smooth' })
    }
  }

  scrollToTop(e) {
    e.preventDefault()
    window.scroll({ top: 0, behavior: 'smooth' })
  }
}

class Accordion {
  constructor(container) {
    this.container = container;
    this.links = container.querySelectorAll('.accordion-more');
    this.content = container.querySelectorAll(".accordion-content");
  }

  init() {
    this.links.forEach(link => {
      link.addEventListener("click", e => {
        e.preventDefault()
        this.toggleLinks(e);
        this.toggleContent(e);
      });
    });
  }

  toggleLinks(e) {
    this.links.forEach(link => link.classList.remove("active"));
    e.target.classList.add("active");
  }

  toggleContent(e) {
    const id = e.target.getAttribute("data-content");
    const content = document.getElementById(id)

    if (content.style.maxHeight) {
      content.style.maxHeight = null
      e.target.classList.remove("active");
    } else {
      content.style.maxHeight = content.scrollHeight + "px";
    }
  }
}

class Modal {
  constructor() {
    this.modal = document.getElementById('modal')
    this.close = this.modal.querySelector('.modal-close')
    this.left = this.modal.querySelector('.modal-left')
    this.right = this.modal.querySelector('.modal-right')

    this.members = document.querySelectorAll('.team-member')

    this.openModal = this.openModal.bind(this)
    this.hideModal = this.hideModal.bind(this)
    this.createModal = this.createModal.bind(this)
  }

  init() {
    this.close.addEventListener('click', this.hideModal)

    this.members.forEach(member => {
      member.addEventListener('click', e => {
        const picture = member.querySelector('.member').cloneNode(true)
        const profile = member.querySelector('.profile').cloneNode(true)

        this.createModal(picture, profile)
        this.openModal()
      })
    })
  }

  hideModal(e) {
    e.preventDefault()
    this.modal.classList.remove('active')
    document.body.classList.remove('body-lock')
    this.left.innerHTML = ""
    this.right.innerHTML = ""
  }

  openModal() {
    document.body.classList.add('body-lock')
    this.modal.classList.add('active')
  }

  createModal(picture, profile) {
    this.left.append(picture)
    this.right.append(profile)
  }
}

class Tabs {
  constructor(container) {
    this.container = container;
    this.tabs = container.querySelectorAll('.tab');
    this.content = container.querySelectorAll('.tab-content');
  }

  init() {
    this.tabs.forEach(tab => {
      tab.addEventListener('click', e => {
        e.preventDefault()
        this.onClick(e)
      })
    })
  }

  onClick(e) {
    this.tabs.forEach(tab => tab.classList.remove("active"));
    e.target.classList.add("active");
    this.showContent(e.target.dataset.content)
  }

  showContent(content) {
    this.content.forEach(content => content.classList.remove("active"));
    const el = document.getElementById(content)
    el.classList.add("active");
  }
}

class RealEstateCities {
  constructor(container) {
    this.tabs = container.querySelectorAll('.city-tab');
    this.cards = document.querySelectorAll('.city-content');
    this.descriptions = document.querySelectorAll('.city-description')
  }

  init() {
    this.filterCities('san-francisco');
    const sf = document.getElementById('san-francisco')
    const napa = document.getElementById('napa')
    const berkeley = document.getElementById('berkeley')
    const santaAna = document.getElementById('santa-ana')
    const sacramento = document.getElementById('sacramento')
    const lagunaNiguel = document.getElementById('laguna-niguel')
    
    sf.addEventListener('click', (e) => {
      e.preventDefault()
      this.onClick(e)
    })

    napa.addEventListener('click', (e) => {
      e.preventDefault()
      this.onClick(e)
    })

    berkeley.addEventListener('click', (e) => {
      e.preventDefault()
      this.onClick(e)
    })

    // santaAna.addEventListener('click', (e) => {
    //   e.preventDefault()
    //   this.onClick(e)
    // })

    // sacramento.addEventListener('click', (e) => {
    //   e.preventDefault()
    //   this.onClick(e)
    // })

    lagunaNiguel.addEventListener('click', (e) => {
      e.preventDefault()
      this.onClick(e)
    })
  }

  onClick(e) {
    this.tabs.forEach(tab => tab.classList.remove("active"));
    this.descriptions.forEach(description => description.classList.remove("active"));
    e.target.classList.add("active");
    const el = document.querySelector("[data-city-description='"+ e.target.id +"']")
    el.classList.add("active")
    this.filterCities(e.target.id)
  }

  filterCities(id) {
    this.cards.forEach(card => {
      if (card.dataset.city === id) {
        card.classList.add("active");
      } else {
        card.classList.remove("active");
      }
    })
  }
}

// Init
const scroll = new Scroll(document.getElementById('navigation'))
const accordion = new Accordion(document.getElementById('accordion'))
const modal = new Modal()
const tabs = new Tabs(document.getElementById('portfolio-tabs'))
const cities = new RealEstateCities(document.getElementById('city-tabs'))

scroll.init()
accordion.init()
modal.init()
tabs.init()
cities.init()
